.letters{ border-bottom: 1px solid $primary; margin-bottom: 100px; margin-top: 30px;
  &__list{ display: flex; justify-content: center; margin-bottom: -2px; }
  &__letter{ margin: 0 1%; }
  &__link{ padding: 20px 0; text-decoration: none; color: $neutral; display: block; border-bottom: 3px solid transparent; font-size: 1.5rem; font-weight: 200;
    &--active{ color: $primary; font-weight: bold; border-bottom: 3px solid $primary; opacity: 1 !important; }
    &--inactive{ opacity: 0.4; }
    &:hover{ color: $primary; font-weight: bold; border-bottom: 3px solid $primary; opacity: 1; }
  }
}

.members{ display: flex; justify-content: flex-start; align-self: flex-start; width: 100%; margin-bottom: 100px; padding-left: 8.5%;
  &__container{ width: 60%; }
  &__letter{ width: 10%; margin-top: 60px; color: $primary; font-weight: bold; font-size: 1.5rem; }
  &__content{}
  &__header{ display: flex; padding-bottom: 30px; border-bottom: 1px solid $neutral-light; }
  &__header-label{ width: 50%; color: #000000; font-size: 1.5rem;
    &:first-of-type{ width: 60%; }
    &:last-of-type{ width: 40%; }
  }
  .member{ display: flex; border-bottom: 1px solid $neutral-light; padding: 25px 10px;
    &__figure{ border: 1px solid $neutral-light; padding: 25px; width: auto; display: inline-block; }
    &__name{ width: 40%; display: flex; align-items: center;
      a {color:$primary; text-decoration: none;
        &:hover { text-decoration: underline;}
      }
    }
    &__image-container{ width: 60%; }
    &__image{ max-height: 50px; }
  }
}


.members-nb{ font-weight: bold; font-size: 1.5rem; color: $primary-lighter; text-decoration: underline; margin-left: 8.5%; margin-top: 50px; display: inline-block; }
