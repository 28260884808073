.post-detail { margin-bottom: 100px;
  &__content {padding-right: 5%;}
  &__sidebar {}
  &__sidebar-cta {margin-top: -180px; padding: 10% 15% 10% 12%; margin-bottom: 40px;
                  background: white; box-shadow: 0 3px 6px transparentize(black, .7);
  }
  &__sidebar-links { margin-bottom: 40px;}
  &__sidebar-link { display: block; padding: 28px 33px; border-radius:$br-size;
                    text-decoration: none; color:$neutral;background-color: transparentize($secondary, .9);
                    position: relative; margin-bottom: 10px;
    &:after {content:'';
             display: inline-block; width: 8px; height: 12px;
             position: absolute; top: 50%; right: 30px; transform: translateY(-50%);
             background: transparent url('../../img/front/arrow-right-neutral.svg') center no-repeat; background-size: contain;
    }
    &:hover { background-color: transparentize($secondary, .8);}

  }
  &__sidebar-quote {position: relative; margin-bottom: 40px;}
  &__sidebar-quote-img-container {padding-top: 72%; position: relative;}
  &__sidebar-quote-img {position: absolute; top: 0; left: 0;
                        width: 100% !important; height: 100% !important; object-fit: cover;
  }
  &__sidebar-quote-content {width: 80%;
                            position: absolute; top: 80%; right: 0;
                            padding: 15px 30px;
                            background: white;
  }
  &__sidebar-quote-text { font-weight: 500; font-size: $fz--h3; line-height: $fz--h3; color:$primary-lighter;}
  &__sidebar-quote-name { font-weight: 300; color: $tertiary;}

  &__desc { margin-bottom: 60px;}
  &__advice {margin-bottom: 60px;
    p { font-size: $fz--h4; font-weight: 300;}
  }
  &__prices { padding: 28px 35px; background: transparentize($primary-lighter, .8); margin-bottom: 60px;
    h3 { color:$primary;}
    p { color: $primary}
  }
  &__training-infos {}
  &__training-required { font-size: $fz--text--bigger; color: $primary !important; font-weight: 700 !important; margin-bottom: 30px;}
  &__training-course { display:flex; align-items:center; margin-bottom: 60px;
    div,
    img { width: 50%;}
  }
  &__training-public { margin-bottom: 60px;}
  &__training-program { margin-bottom: 60px;}
  &__training-terms { margin-bottom: 60px;}
  &__testimonials { display:flex;  }
  &__testimonial {width: calc((100% - 30px)/3); margin-right: 15px; padding: 20px;
                  position: relative;
    &:before {content:'';
              display: inline-block; width: 40px; height: 40px;
              position: absolute; top: 0; left: 0; z-index: -1;
              background: transparent url('../../img/front/quote-secondary.svg') center no-repeat; background-size: contain;
              opacity: .5;
    }
  }
  &__testimonial-content {font-size: $fz--text--big;}
  &__testimonial-name {font-size: $fz--text--small; color:$primary;}
}

.sidebar-training-sessions { margin-bottom: 40px;
  &__title { color:$primary; font-size: $fz--text--bigger; font-weight: 700; margin-bottom: 10px;}
  &__container {}
  &__item { border-top:1px solid $primary; display:flex; align-items: center; padding: 10px 0;
    &:last-of-type { border-bottom:1px solid $primary;}
  }
  &__link { display:flex; padding: 10px; text-decoration: none; position: relative;
    &:after {content:'';
             display: inline-block; width: 8px; height: 8px;
             position: absolute; top: 50%; right: 0; transform: translateY(-50%);
             background: transparent url('../../img/front/arrow-right-primary-small.svg') center no-repeat; background-size: contain;
    }
  }
  &__item-thumbnail-container { position: relative;width: 20%; align-self:flex-start;
                               padding-top: 10%; border-radius: 0 0 $br-size--small 0; overflow: hidden;
                               border: 2px solid transparent;
    &:after {content:'';
             display: inline-block; width: 100%; height: 100%;
             position: absolute; top: 0; left: 0;
             background: transparentize($secondary-darker, .9) url('../../img/front/thumbnail-picto-training.svg') center no-repeat; background-size: 40%;background-blend-mode: multiply;
    }
  }
  &__item-thumbnail {}
  &__item-content {width: 80%; padding: 0 20px;
    p { margin-bottom: 0;}
  }
  &__item-date { font-weight: 500; color:$primary; margin-bottom: 5px;}
  &__item-title { font-size: $fz--text; line-height: 1.2; color:$tertiary;}
  &__item-place { font-size: $fz--text; line-height: 1.2; color:$primary;}
}
