// grid

$width: 100%;
$gutter: 2%;
$breakpoint-med: $large-screen; // 1024



.row {
  position: relative;
  display:flex;
  flex-wrap:wrap;
  width: 100%;
  &.row-equal-height {
    align-items: stretch;
  }
}

.row [class^="col"] {
  margin: 0.5rem 1%;
  margin-bottom: 0;
  min-height: 0.125rem;
}
.hidden-sm { display: none;}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

.col-1-sm { width:calc($width / 12) - calc($gutter * 11 / 12); }
.col-2-sm { width: calc($width / 6) - calc($gutter * 10 / 12); }
.col-3-sm { width: calc($width / 4) - calc($gutter * 9 / 12); }
.col-4-sm { width: calc($width / 3) - calc($gutter * 8 / 12); }
.col-5-sm { width: calc($width / (12 / 5)) - calc($gutter * 7 / 12); }
.col-6-sm { width: calc($width / 2) - calc($gutter * 6 / 12); }
.col-7-sm { width: calc($width / (12 / 7)) - calc($gutter * 5 / 12); }
.col-8-sm { width: calc($width / (12 / 8)) - calc($gutter * 4 / 12); }
.col-9-sm { width: calc($width / (12 / 9)) - calc($gutter * 3 / 12); }
.col-10-sm { width: calc($width / (12 / 10)) - calc($gutter * 2 / 12); }
.col-11-sm { width: calc($width / (12 / 11)) - calc($gutter * 1 / 12); }
.col-12-sm { width: $width; }



@media only screen and (min-width: $large-screen) {
  .row [class^="col"] { margin-bottom: 0.5rem;}
  .row { flex-wrap: nowrap;}
  .col-1 { width:calc($width / 12) - calc($gutter * 11 / 12); }
  .col-2 { width: calc($width / 6) - calc($gutter * 10 / 12); }
  .col-3 { width: calc($width / 4) - calc($gutter * 9 / 12); }
  .col-4 { width: calc($width / 3) - calc($gutter * 8 / 12); }
  .col-5 { width: calc($width / (12 / 5)) - calc($gutter * 7 / 12); }
  .col-6 { width: calc($width / 2) - calc($gutter * 6 / 12); }
  .col-7 { width: calc($width / (12 / 7)) - calc($gutter * 5 / 12); }
  .col-8 { width: calc($width / (12 / 8)) - calc($gutter * 4 / 12); }
  .col-9 { width: calc($width / (12 / 9)) - calc($gutter * 3 / 12); }
  .col-10 { width: calc($width / (12 / 10)) - calc($gutter * 2 / 12); }
  .col-11 { width: calc($width / (12 / 11)) - calc($gutter * 1 / 12); }
  .col-12 { width: $width; }

  .hidden-sm {
    display: block;
  }
}
