.edito {
  &__intro { margin-bottom: 60px;}
  &__intro-strong {font-size: $fz--text--bigger; color:$primary; font-weight: 700 !important;}
  &__intro-text {font-size: $fz--text--big;}
}

.team {
  h2 {text-align: center;}
}
.team-list {
  &__title { margin-bottom: 20px;}
  &__container {display:flex; flex-wrap:wrap;}
  &__item {width: calc((100% - 45px)/4); margin-right: 15px; margin-bottom: 50px;position: relative;
    @include every(4) {margin-right: 0;}
  }
  &__item-thumbnail-container {position: relative; overflow: hidden; border-radius: 0 0 $br-size--med 0;
                               padding-top: 72%;
  }
  &__item-thumbnail{position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%);
                     width:100%; height: 100%; object-fit: cover; filter: grayscale(1) brightness(1.1);
  }
  &__item-content { padding-top: 28px;
    p { margin-bottom: 0;}
  }
  &__item-name {font-size: $fz--text--bigger; color:$primary; font-weight: 700 !important; margin-bottom: 5px !important;}
  &__item-function {text-transform: uppercase; font-weight: 500; font-size: $fz--text--big; line-height: 20px; margin-bottom: 15px !important;}
  &__item-mail {font-weight: 500; font-size: $fz--text--big; line-height: 20px;
    a {text-decoration: none; color:$neutral !important;
      &:hover,
      &:focus {text-decoration: underline;}
    }
  }
}

.join{
  &__quote {}
  &__quote-content { padding: 24px 0; border-top: 1px solid $neutral; border-bottom: 1px solid $neutral;
    p { font-size: 1.5rem; font-weight: 700; text-align: center; line-height: 26px;
      & + p { font-size: $fz--text; font-weight: 300; margin-bottom: 0;
        strong {font-weight: bold;}
      }
    }
  }
  &__modal-content { display: none;}
}

.join-argumental { margin-top: 50px;
  &__item {padding: 60px 55px 40px; position: relative; margin: 70px 0;
    &--primary { background: transparentize($primary, .9);
      .join-argumental__cartouche { background: $primary;}
    }
    &--secondary { background: transparentize($secondary, .9);
      .join-argumental__cartouche { background: $secondary;}
    }
    &--primary-lighter { background: transparentize($primary-lighter, .9);
      .join-argumental__cartouche { background: $primary-lighter;}
    }
  }
  &__cartouche { position: absolute; top: 0; left: 0; transform: translate(-100px, -70%) rotate(-10deg);
                 display: block; padding: 10px 50px; border-radius: $br-size--big;
                 color:white; text-align: center; font-size: $fz--text;
      strong { display: block; font-weight: bold; text-transform: uppercase;}
  }
  &__content {}
}

.join-avantages {
  &__list-container {display:flex; justify-content:space-between;}
  &__item {display:inline-flex; align-items:center; justify-content:center;
           width: 25%; padding: 50px 0;
           background: $primary;
           color:white; font-size: 1.5rem; line-height: 19px;
     &:before {content:none !important;}
     &:hover{cursor:pointer;}
  }
}

.join-steps {
  &__asterisk {display: block; font-size: $fz--text--small;}
}


@media screen and (max-width: $large-screen) {
  .team-list {
    &__item {width: calc((100% - 15px)/2); margin-right: 15px;
      @include every(2) {margin-right: 0;}
      @include every(4) {margin-right: 0;}
    }
  }
  .join-argumental {padding: 0 20px;
    &__cartouche { transform: rotate(0) translate(-20px,-70%);}
  }
}

@media screen and (max-width: $x-small-screen) {
  .team-list {
    &__item {width: 100%; margin-right: 0;}
  }
}
