.list-search { margin-top: -120px;}
.search-form {
  &__title {display: none;
    .home-search & { display: block; font-weight: 500; color:$primary; text-decoration: underline; margin-bottom: 40px;}
  }
  &__container { margin-bottom: 50px;}
  &__search {display:flex; align-items:stretch;
             width: 100%;
             background: white; box-shadow: 0 3px 6px transparentize(black, .7);
             padding: 5px; border-radius: $br-size--big;
    div {
      &:first-of-type { flex-grow: 1; padding: 12px 20px;
        input[type="text"] {background-color: white; margin-top: 0; border:none;}
      }
      &:last-of-type {
        button {padding: 20px 50px; height: 100%;
                color: white; font-family: 'Serenity', sans-serif;font-weight: 500; text-decoration: none; border:none; font-size: $fz--text--big;
                line-height: 1; border-radius: 30px;
                transition: background-color .2s linear;
                appearance:none;
                @include lhCrop(1);
                background-color: $primary;border: 1px solid $primary;
            &:hover { cursor:pointer;
                      background-color: $primary-lighter;border: 1px solid $primary-lighter;
            }
        }
      }
    }
  }
  &__toggle-filters { appearance:none; font-size: $fz--text; color:$primary; font-weight: 500;
              display: inline-block; position: relative;
              border:none; background-color: transparent;
              text-decoration: underline;
    &:after {content:'';
             display: inline-block;width: 9px; height: 5px; margin-left: 5px;
             background: transparent url('../../img/front/arrow-down-primary.svg') center no-repeat; background-size: contain;
    }
  }
  &__filters {display:flex; flex-wrap:wrap; padding: 30px 0;}
  &__filter {width: 50%; padding: 8px; margin-bottom: 20px;
    select { appearance:none; border:1px solid $primary; background: white; border-radius: $br-size--big;
             padding: 16px 50px 16px 22px;
             font-size: $fz--text--bigger; color:$primary; font-weight: 500;
             background: white url('../../img/front/arrow-down-primary-slim.svg') right 25px center no-repeat; background-size: 4%;
    }
  }
}

@media screen and (max-width: $x-small-screen) {
  .search-form {
    &__filter {width: 100%;}
  }
}
