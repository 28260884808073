.btn-container {width: 100%;
  &--left { text-align: left;}
  &--centered { text-align: center;}
  &--right { text-align: right;}
  &--space-around { display:flex; flex-wrap:wrap; align-items:center; justify-content: space-around;}
  &--space-between { display:flex; flex-wrap:wrap; align-items:center; justify-content: space-between;}
  &--row-reverse { flex-direction:row-reverse;}
  .btn {margin-right: 10px;
    &:last-of-type {margin-right: 0;}
  }
}

.btn { position: relative; z-index: 1;
       display: inline-block; padding: 20px 50px;
       color: white; font-family: 'Serenity', sans-serif;font-weight: 500; text-decoration: none; border:none; font-size: $fz--text--big;
       line-height: 1; border-radius: 30px;
       transition: background-color .2s linear;
       @include lhCrop(1);
       &:hover { cursor:pointer;}

  &--icon-left { padding: 15px 50px 15px 85px !important; text-transform: uppercase;
    &:after { content:''; position: absolute; top: 50%; left: 45px; transform: translateY(-50%);
      display: inline-block; width: 22px; height: 22px;
      background-size: contain;
      line-height: 1;
      transition: left .2s linear;
    }
  }
  &--big {padding: 25px 50px; text-transform: uppercase; font-size: $fz--text;}
  &--small {padding: 12px 20px; font-size: 1.125rem;}
  &--primary { background-color: $primary;border: 1px solid $primary;
    &:hover { background-color: $primary-lighter;border: 1px solid $primary-lighter; }
  }
  &--primary-alt { background-color: white; border: 1px solid $primary; color:$primary;
    &:hover { background-color: $primary; border: 1px solid white; color:white; }
  }
  &--primary-light { background-color: $primary-lighter;border: 1px solid $primary-lighter;
    &:hover { background-color: $primary;border: 1px solid $primary; }
  }
  &--secondary { background-color: $secondary;border: 1px solid $secondary;
    &:hover { background-color: $secondary-lighter;border: 1px solid $secondary-lighter;}
  }
  &--neutral { background-color: $neutral;border: 1px solid $neutral;
    &:hover { background-color: $neutral-darker;border: 1px solid $neutral-darker;}
  }
  &--alt { background-color: transparent; color: $secondary; border: 1px solid $secondary;}
  &--danger { background-color: $danger;border: 1px solid $danger;
    &:hover { background-color: darken($danger, 10%);border: 1px solid darken($danger, 10%); }
  }
  &--danger-alt { background-color: transparent ;border: 1px solid $danger; color:$danger;
    &:hover { background-color: $danger; color:white; }
  }
  &--warning { background-color: $warning;border: 1px solid $warning;
    &:hover { background-color: darken($warning, 10%);border: 1px solid darken($warning, 10%); }
  }
  &--warning-alt { background-color: transparent ;border: 1px solid $warning; color:$warning;
    &:hover { background-color: $warning; color:white; }
  }
  &--success { background-color: $success;border: 1px solid $success;
    &:hover { background-color: darken($success, 10%);border: 1px solid darken($success, 10%); }
  }
  &--success-alt { background-color: transparent ;border: 1px solid $success; color:$success;
    &:hover { background-color: $success; color:white; }
  }
  &:disabled{ opacity: 0.5; }
}

@media screen and (max-width: $small-screen) {
  .btn {
  }
}
