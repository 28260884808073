.post-list {
  &__intro {
    p {margin-bottom: 10px;}
  }
  &__intro-strong {font-size: $fz--text--bigger; color:$primary; font-weight: 700 !important;}
  &__intro-logo {max-width: 180px; height: auto; margin: 15px 0;}
  &__intro-text {font-size: $fz--text--big;}
  &__intro-keys {
    &:last-of-type { margin-bottom: 30px;}
  }
  &__intro-key-value {display: inline-block; font-size: $fz--text--bigger; color:$primary; font-weight: 700 !important; margin-right: 5px;}
  &__intro-key-label {display: inline-block;font-size: $fz--text--big; margin-right: 5px;}
  &__title { color:$primary; padding-bottom: 20px; border-bottom:1px solid $primary; margin-bottom: -40px; margin-top: 30px; font-weight: 700;}
  &__detail-list-title {font-weight: bold; color:$tertiary; margin-bottom: -60px;}
  &__container {display:flex; flex-wrap:wrap; margin-top: 100px;}
  &__item {width: calc((100% - 45px)/4); margin-right: 15px; margin-bottom: 50px;position: relative;
    @include every(4) {margin-right: 0;}
    &:hover {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {border: 2px solid $neutral;}
        &__item-type {color:white; border: 2px solid $neutral; background-color: $neutral;}
      }
    }
  }
  &__item-link { text-decoration: none;}
  &__item-thumbnail-container { position: relative;
                               padding-top: 67%; border-radius: 0 0 $br-size--big 0; overflow: hidden;
                               border: 2px solid transparent;
    &:after {content:'';
             display: inline-block; width: 100%; height: 100%;
             position: absolute; top: 0; left: 0;
             background: transparentize($neutral, .9) url('../../img/front/thumbnail-picto.svg') center no-repeat; background-size: 40%;
             background-blend-mode: multiply;
    }
  }
  &__item-thumbnail {position: absolute; top: 0; left: 0; width: 100%; height: 100% !important; object-fit: cover; z-index: 2;}
  &__item-unauthorized {position: absolute; top: 0; left: 0; z-index: 2;
                        width: 100%; height: 100%;
                        display:flex; flex-direction:column; align-items:center; justify-content:center;
                        background: transparentize(#F7F7F7, .2);
  }
  &__item-unauthorized-picto {display: inline-block; width: 28px;height: 30px;
                              background: transparent url('../../img/front/post-list-locked.svg') center no-repeat; background-size: contain;
                              margin-bottom: 10px;
  }
  &__item-unauthorized-text {color:$neutral; font-size: $fz--text--big; font-weight: 500;}
  &__item-type {position: absolute; top: 0; left: 0; transform:translate(-5px, -50%) rotate(-5deg); z-index: 3;
               display: inline-block; padding: 0 12px;
               font-weight: 700; color:$neutral;
               background:white;
               border: 2px solid $neutral; border-radius: 30px;
  }
  &__item-content {padding: 20px 0;}
  &__item-tags {display:flex; margin-bottom: 15px;}
  &__item-tag {display: inline-block; padding: 4px 15px; margin-right: 5px;
          font-size: $fz--text--smaller; color:white; font-weight: 500; text-transform: uppercase;
          border-radius: $br-size--small;
    &--status {background-color: $primary-lighter;}
    &--format {background-color: $tertiary;}
    &--level {background-color: $secondary;}
  }
  &__item-content-top {}
  &__item-top-content {display:block; color:$neutral; font-weight: 500; font-size: $fz--text--big;}
  &__item-date { color:$neutral;}
  &__item-title { color:$neutral; font-weight: 700 !important; font-size: $fz--text--bigger; line-height: 22px; padding-top: 15px;}
  &__item {/* Déclinaisons */
    &--document {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {
          &:after {background: transparentize($primary, .9) url('../../img/front/thumbnail-picto-documents.svg') center no-repeat; background-size: 40%;background-blend-mode: multiply;}
        }
        &__item-type {color:$primary; border: 2px solid $primary;}
      }
      &:hover {
        .post-list {/* RIEN ICI */
          &__item-thumbnail-container {border: 2px solid $primary;}
          &__item-type {color:white; border: 2px solid $primary; background-color: $primary;}
        }
      }
    }
    &--services {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {
          &:after {background: transparentize($primary-lighter, .9) url('../../img/front/thumbnail-picto-services.svg') center no-repeat; background-size: 40%;background-blend-mode: multiply;}
        }
        &__item-type {color:$primary-lighter; border: 2px solid $primary-lighter;}
      }
      &:hover {
        .post-list {/* RIEN ICI */
          &__item-thumbnail-container {border: 2px solid $primary-lighter;}
          &__item-type {color:white; border: 2px solid $primary-lighter; background-color: $primary-lighter;}
        }
      }
    }
    &--training {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {
          &:after {background: transparentize($secondary-darker, .9) url('../../img/front/thumbnail-picto-training.svg') center no-repeat; background-size: 40%;background-blend-mode: multiply;}
        }
        &__item-type {color:$secondary; border: 2px solid $secondary;}
      }
      &:hover {
        .post-list {/* RIEN ICI */
          &__item-thumbnail-container {border: 2px solid $secondary;}
          &__item-type {color:white; border: 2px solid $secondary; background-color: $secondary;}
        }
      }
    }
    &--event {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {
          &:after {background: transparentize($primary-lighter, .9) url('../../img/front/thumbnail-picto-event.svg') center no-repeat; background-size: 40%;background-blend-mode: multiply;}
        }
        &__item-type {color:$primary-lighter-alt; border: 2px solid $primary-lighter-alt;}
      }
      &:hover {
        .post-list {/* RIEN ICI */
          &__item-thumbnail-container {border: 2px solid $primary-lighter-alt;}
          &__item-type {color:white; border: 2px solid $primary-lighter-alt; background-color: $primary-lighter-alt;}
        }
      }
    }
    &:hover {
      .post-list {/* RIEN ICI */
        &__item-thumbnail-container {border: 2px solid $neutral;}
        &__item-type {color:white; border: 2px solid $neutral; background-color: $neutral;}
      }
    }
  }
}

@media screen and (max-width: $large-screen) {
  .post-list {
    &__item {width: calc((100% - 15px)/2); margin-right: 15px;
      @include every(2) {margin-right: 0;}
      @include every(4) {margin-right: 0;}
    }
  }
}

@media screen and (max-width: $x-small-screen) {
  .post-list {
    &__item {width: 100%; margin-right: 0;}
  }
}
