//##     ## ######## ##       ########  ######## ########   ######
//##     ## ##       ##       ##     ## ##       ##     ## ##    ##
//##     ## ##       ##       ##     ## ##       ##     ## ##
//######### ######   ##       ########  ######   ########   ######
//##     ## ##       ##       ##        ##       ##   ##         ##
//##     ## ##       ##       ##        ##       ##    ##  ##    ##
//##     ## ######## ######## ##        ######## ##     ##  ######

.d-flex{ display: flex;
  &--justify-end{ justify-content: flex-end; }
  &--justify-between{ justify-content: space-between; }
  &--align-items-center{ align-items: center; }
  &--align-items-start{ align-items: flex-start; }
  &--align-items-end{ align-items: flex-end; }
}

// ######   ######## ##    ## ######## ########     ###    ##
//##    ##  ##       ###   ## ##       ##     ##   ## ##   ##
//##        ##       ####  ## ##       ##     ##  ##   ##  ##
//##   #### ######   ## ## ## ######   ########  ##     ## ##
//##    ##  ##       ##  #### ##       ##   ##   ######### ##
//##    ##  ##       ##   ### ##       ##    ##  ##     ## ##
// ######   ######## ##    ## ######## ##     ## ##     ## ########


.sidelinks { position:fixed; right: 0;top: 40%; display:flex; flex-direction:column; align-items: flex-end; z-index: 100;
  &__item { min-width: 76px; height: 76px; border-radius: 40px; padding: 0 20px; margin-bottom: -10px; position: relative; z-index: 2;
            display: flex; align-items:center; justify-content:center;
            overflow: hidden;
            text-decoration: none;
    &:last-of-type { z-index: -2;}
    &--contact {background: $primary-lighter;
      i { display: inline-block; width: 30px; height: 30px;
          background: transparent url('../../img/front/contact.svg') center no-repeat; background-size: contain;}
    }
    &--download {background: $secondary;
      i { display: inline-block; width: 20px; height: 25px;
          background: transparent url('../../img/front/download.svg') center no-repeat; background-size: contain;}
    }
    span {display: none; font-size: $fz--text--small; font-weight: 700; color:white;
          padding:0 20px;
        }
    &:hover {
      span {display: inline-block;}
    }
  }
}

@media screen and (max-width: $medium-screen) {
  .sidelinks {
    &__item {min-width: 50px; height: 50px; padding: 0;
      &:hover { padding: 0 20px;}
    }
  }
}

@media screen and (max-width: $x-small-screen) {
  .sidelinks {display: none;}
}
