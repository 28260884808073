body { overflow-x: hidden;}


.content-wrapper {}

.container { width: 100%; max-width: 1640px; margin: 0 auto;
}

.inner-content {
  img { width: 100%; height: auto;}
  &--centered{ display: flex; align-items: center; justify-content: center; height: 100vh; flex-direction: column; }
}

@media screen and (max-width: $xlarge-screen) {
.container {padding: 0 20px;}
}

@media screen and (max-width: $medium-screen) {

}
