.home-intro { margin-bottom: 50px;
  .row { align-items:stretch;}
  .col-5 {margin-right: 0;}
  &__content {padding-left: 18%; padding-right: 5%; padding-bottom: 120px;}
  &__text-sup { text-transform: uppercase; font-size: $fz--text--big; font-weight: 300; color:$primary; letter-spacing: 4.2px; line-height: 52px;}
  &__text { font-size: $fz--h1; color: $primary; font-weight: 700 !important; line-height: 52px !important; margin-bottom: 0 !important;}

  &__img-container { margin: 0 !important; flex-grow: 1;
    img {width: 100%; height: 100%; object-fit:cover;}
  }
  &__entries { display:flex; justify-content: center; transform:translateY(-50%);}
  &__entry {position: relative;
            display: flex; justify-content:center; align-items:center;
            width: 260px; height: 140px; margin: 0 2%;
            background: $primary; border-radius: 0 0 $br-size 0;
            color:white; text-decoration: none;
    &:hover {background: $primary-lighter;}
  }
  &__entry-picto {position: absolute; top: 0; left: 0; transform: translate(-50%,-50%);
                  display: inline-block; width: 77px; height: 77px;
                  border-radius:50%; box-shadow: 0 3px 6px #00000029;
    &--quality {background: $primary-lightest url('../../img/front/home-intro-quality.svg') center no-repeat; background-size: 60%;}
    &--process {background: $primary-lightest url('../../img/front/home-intro-process.svg') center no-repeat; background-size: 70%;}
    &--security {background: $primary-lightest url('../../img/front/home-intro-security.svg') center no-repeat; background-size: 70%;}
    &--environment {background: $primary-lightest url('../../img/front/home-intro-environment.svg') center no-repeat; background-size: 60%;}
  }
  &__entry-text { font-size: 2.125rem; font-weight: 500;line-height: 30px; text-align: center; padding: 0 20px;}
}

.home-search { background: transparentize($primary, .9); padding-top: 50px; margin-bottom: 100px;}

.home-agenda { position: relative; z-index: 3;
  h1 { margin-bottom: 0;}
  &__header {display:flex; flex-wrap: wrap; justify-content: space-between; align-items:center;}
  &__view-all { color:$primary; }
  &__subtitle {width: 100%; color:$primary; font-size: $fz--text--big; font-weight: 700; padding: 35px 0;}
  &__list-container {}
  &__list {}
  &__item {border-top:1px solid $primary;
    &:last-of-type {border-bottom: 1px solid $primary; }
  }
  &__item-link {display:flex; align-items:center; position: relative;
                padding: 40px 0; text-decoration: none;
    &:after {content:'';
             display: inline-block; width: 8px; height: 8px;
             position: absolute; top: 50%; right: 0; transform: translateY(-50%);
             background: transparent url('../../img/front/arrow-right-primary-small.svg') center no-repeat; background-size: contain;
    }
  }
  &__item-thumbnail-container {width: 108px; height: 81px;}
  &__item-content { padding:0 30px;}
  &__item-date {color:$primary; font-size: 1.125rem; margin-bottom: 5px;}
  &__item-title {color:$text-color; font-size: 1.125rem; line-height: 20px; }
}

.home-news {
  h1 { margin-bottom: 0;}
  &__header {display:flex; flex-wrap: wrap; justify-content: space-between;align-items:center; margin-bottom: 50px;}
  &__view-all { color:$primary; }
  &__swiper {overflow: visible; position: relative; z-index: 1; pointer-events: initial; margin-bottom: 100px;
    &:before {content:'';
              display: inline-block; width: 50vw; height: 110%;
              position: absolute; top: 50%; right: calc(100% + 10px); z-index: 2; transform: translateY(-50%);
              background-color: white;
    }
  }
  &__item {width: 400px;
    &:hover {
      .home-news {/* RIEN ICI */
        &__item-thumbnail-container {border: 2px solid $neutral;}
        &__item-cat {color:white; border: 2px solid $neutral; background-color: $neutral;}
      }
    }
  }
  &__item-link { text-decoration: none;}
  &__item-thumbnail-container {position: relative;
                               border: 2px solid transparent;
                               padding-top: 67%;
    &:after {content:'';
             display: inline-block; width: 100%; height: 100%;
             position: absolute; top: 0; left: 0;
             background: transparentize($neutral, .9) url('../../img/front/thumbnail-picto.svg') center no-repeat; background-size: 40%;
             background-blend-mode: multiply;
    }
  }
  &__item-thumbnail {}
  &__item-cat {position: absolute; top: 0; left: 0; transform:translate(-10px, -50%) rotate(-5deg); z-index: 2;
               display: inline-block; padding: 0 12px;
               font-weight: 700; color:$neutral;
               background:white;
               border: 2px solid $neutral; border-radius: 30px;
  }
  &__item-content {padding: 20px 0;}
  &__item-date { display: inline-block;
                 color:$neutral; margin-bottom: 16px;
               }
  &__item-title { color:$neutral; font-weight: 700; font-size: $fz--text--big; line-height: 22px;}
  &__scrollbar { margin-top: 15px; height: 2px; background: transparentize($primary, .8); border-radius:4px; margin-bottom: 15px;
    .swiper-scrollbar-drag {height: 100%; background: $primary;border-radius:4px;}
  }
  &__pagination {}
  &__swiper-nav {display: inline-block; width: 28px; height: 19px;
    &--prev {background: transparent url('../../img/front/arrow-left-primary.svg') center no-repeat; background-size: contain; margin-right: 10px;
      &.swiper-button-disabled {background: transparent url('../../img/front/arrow-left-primary-light.svg') center no-repeat; background-size: contain;}
    }
    &--next {background: transparent url('../../img/front/arrow-right-primary.svg') center no-repeat; background-size: contain;
      &.swiper-button-disabled {background: transparent url('../../img/front/arrow-right-primary-light.svg') center no-repeat; background-size: contain;}
    }
  }
}

.home-edito { background: #F0F8F1 url('../../img/front/home-edito-bg.svg') left -35px center no-repeat; background-size: contain; padding: 200px 0; margin-top: 50px;
  &__text {font-size: $fz--h4; font-weight: 300; line-height: 26px; padding-right: 10%; margin-bottom: 25px;}
}

.home-keyfigures {
  &__line {display:flex; justify-content: center;
    &--2 { margin-top: -50px;}
  }
  &__hex {width: 290px; height: 290px;
          -webkit-clip-path: polygon( 50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25% );
          clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
          background-color: #D5EAD8;
          display:flex; justify-content:center; align-items: center; flex-direction:column;
    &--title {background-color: #6BB775;}
  }
  &__title { font-size: $fz--h2; color:white; font-weight: 700; line-height: 42px; padding: 0 50px; text-align: center;}
  &__item-key { font-size: $fz--h1; color: $tertiary; font-weight: 700; line-height: 58px; margin-bottom: 10px;
                position: relative; z-index: 1;
                padding: 0 10px;
    &:before {content:''; z-index: -1;
              position: absolute; bottom: 0; left: 0;
              width: 100%; height: 24px;
              background-color: $secondary-darker;
    }

  }
  &__item-label {font-size: $fz--text--big; text-transform: uppercase; font-weight: 300; color: $primary; text-align: center;
                 display: inline-block; padding: 0 30px;
  }
}

.home-partners { padding: 100px 0; margin-bottom: 50px;
  &__title { margin-bottom: 70px;}
  &__swiper-container {display:flex; justify-content:space-around; align-items:center; padding: 0 50px;}
  &__swiper {margin:0 50px;}
  &__item {border:1px solid $neutral-light;}
  &__item-logo-container {display: block; padding-top: 100%; position: relative;}
  &__item-logo {position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
                width: 80%; height: 80%; object-fit:contain;
  }
  &__swiper-nav {display: inline-block; width: 28px; height: 19px;
    &--prev {background: transparent url('../../img/front/arrow-left-primary.svg') center no-repeat; background-size: contain;
      &.swiper-button-disabled {background: transparent url('../../img/front/arrow-left-primary-light.svg') center no-repeat; background-size: contain;}
    }
    &--next {background: transparent url('../../img/front/arrow-right-primary.svg') center no-repeat; background-size: contain;
      &.swiper-button-disabled {background: transparent url('../../img/front/arrow-right-primary-light.svg') center no-repeat; background-size: contain;}
    }
  }
}

@media screen and (max-width: $large-screen) {
  .home-intro{
    &__content {padding-left: 20px;}
    &__text {font-size: calc(#{$fz--h1} / 1.5); line-height: 1.3 !important;}
  }
}

@media screen and (max-width: $medium-screen) {
  .home-intro {
    &__entries {flex-wrap:wrap; transform: translateY(0); margin-top: 50px; justify-content:space-between; padding: 0 20px;}
    &__entry { width: 40%; margin-bottom: 50px;}
  }

  .home-keyfigures { padding-top: 20px;
    &__hex { width: 200px; height: 200px; margin-top: 10px;}
  }
}

@media screen and (max-width: $medium-screen) {
  .home-intro {
    &__entry { width: 100%; height: auto; padding: 30px 0;}
  }

  .home-edito {background: #F0F8F1 url('../../img/front/home-edito-bg.svg') left -35px top 40px no-repeat; background-size: contain;
  }

  .home-keyfigures { display:flex; flex-direction:column; padding-top: 200px;
    &__line { flex-wrap: wrap;
      &:first-of-type {order:2;}
      &:last-of-type {order:1;}
    }
    &__hex {margin-top: -40px;}
  }

  .home-partners {
    &__swiper-container { padding: 0;}
  }
  
  .home-news{
    &__swiper{ overflow: hidden; max-width: 100%; }
  }
}

@media screen and (max-width: $small-screen) {
  .home-keyfigures { display:flex; flex-direction:column; padding-top: 200px;
    &__title { font-size: $fz--h2; color:white; font-weight: 700; line-height: 42px; padding: 0 50px; text-align: center;}
    &__item-key { font-size: $fz--h2; color: $tertiary; font-weight: 700; line-height: 42px; margin-bottom: 10px;
                  position: relative; z-index: 1;
                  padding: 0 10px;

    }
    &__item-label {font-size: $fz--text; text-transform: uppercase; font-weight: 300; color: $primary; text-align: center;
                   display: inline-block; padding: 0 30px;
    }
  }

}
