.pagination { padding: 20px 0; text-align: center;
  &__link, .page-item { display: inline-block; padding: 5px 20px;
            text-decoration: none; font-weight: 400; color: $neutral;
    &--active, &.active {color:$primary; font-weight: 700; text-decoration: none !important;}
    &:hover {
      a { text-decoration: underline; }
    }
  }
  .page-item{
    a{ text-decoration: none; color: $neutral; }
    &:before{ display: none; }
  }
}
