.page-header { margin-bottom: 75px; position: relative;
  &__top {}
  &__bottom {background: transparentize($primary-lighter, .8) url('../../img/front/page-header-bg-primary-lighter.svg') right -10px center no-repeat; background-size: contain; padding: 40px 0 80px;
    h1 { padding-left: 8.5%; margin-bottom: 0;}
  }
  &__link-container{ margin-top: -115px; margin-bottom: 40px;
    .col-12 {display:flex; justify-content:flex-end;}
  }
  &__post-infos { padding-left: 30px; border-left: 1px solid $tertiary;
    h1 { padding-left: 0; font-size: $fz--h2; color:$tertiary; margin-bottom: 20px; max-width: 520px;}
  }
  &__post-tags {display:flex; margin-bottom: 15px;}
  &__post-tag {display: inline-block; padding: 4px 15px; margin-right: 5px;
          font-size: $fz--text--smaller; color:white; font-weight: 500; text-transform: uppercase;
          border-radius: $br-size--small;
    &--status {background-color: $primary-lighter;}
    &--format {background-color: $tertiary;}
    &--level {background-color: $secondary;}
  }
  &__post-infos-bottom { display:flex; align-items: center; margin-top: 20px;
    &:first-of-type {margin-top: 0;}
  }
  &__post-infos-item {font-size: $fz--text--bigger; margin-right: 30px; display:inline-flex; align-items:center; line-height: 1;
    &--date {
      i {display: inline-block; width: 20px; height: 20px; margin-right: 10px;
         background: transparent url('../../img/front/event-date.svg') center no-repeat; background-size: contain;
      }
      > small{ margin-left: 10px; align-self: flex-end; }
    }
    &--duration {
      i {display: inline-block; width: 20px; height: 20px; margin-right: 10px;
         background: transparent url('../../img/front/training-duration.svg') center no-repeat; background-size: contain;
      }
    }
    &--capacity {
      i {display: inline-block; width: 20px; height: 20px; margin-right: 10px;
        background: transparent url('../../img/front/capacity.svg') center no-repeat; background-size: contain;
      }
    }
    &--language {}
  }
  &--training {
    .page-header{ /*rien ici*/
      &__bottom {background: $secondary-lightest url('../../img/front/page-header-bg-secondary-lighter.svg') right -10px center no-repeat; background-size: contain;}
    }
  }
  &--detail {
    .page-header{ /*rien ici*/
      &__bottom {background: transparentize($primary-lighter, .9) url('../../img/front/page-header-bg-primary-lighter.svg') right -10px center no-repeat; background-size: contain;}
    }
  }
}
