.header { position:fixed; width: 100%; background: white; z-index: 12;
  &__top {background: $primary; color:white; padding: 20px 0;
    .col-12 {display:flex; justify-content:flex-end; align-items:center;}
  }
  &__search { display: inline-block; width: 13px; height: 13px; margin-right: 20px;
              background: transparent url('../../img/front/search-white.svg') center no-repeat; background-size: contain;
  }
  &__connect {color:white; text-decoration: underline; letter-spacing: .64px;font-weight: 500; margin-right: 20px;
    &:hover { text-decoration: none;}
  }
  &__main { padding: 20px 0;
    .row {align-items:center;}
  }
  &__logo-container {display: block;}
  &__logo { max-width: 205px;}
  &__menu-wrapper {display:flex; align-items:center;justify-content:flex-end;}
  &__menu {display:flex; justify-content:flex-end;}
  &__menu-item-link {padding: 0 20px; color:$neutral; text-decoration: none; font-size: $fz--text--big;
    &--sign-up { padding: 13px 17px; background: $primary-lighter; border-radius:30px; color:white; font-weight: 700; white-space: nowrap }
    &:hover{ color: $primary; }
     &--active{ font-weight: bold; color: $primary; }
  }
  &__mobile-menu-toggle,
  &__mobile-menu-top-links,
  &__mobile-menu-bottom-links { display: none;}

}

.langswitcher { position: relative;
  &__active {display:inline-flex; align-items:center; padding-left: 5px;
    &:after {content:'';
             display: inline-block; width: 10px; height: 5px; margin-left: 5px;
             background: transparent url('../../img/front/arrow-down-white.svg') center no-repeat; background-size: contain;
    }

  }
  &__available {display: none; position: absolute; top: 100%; left: 0; width: 100%;
                background-color: white;
  }
  &__available-item { display: inline-block; width: 100%; padding: 5px;
    a {color:$primary; text-decoration: none;}
    &:hover {background: $primary;
      a { color:white;}
    }
  }
  &:hover { cursor:pointer;
    .langswitcher {
      &__available { display: block;}
    }
  }
}


@media screen and (max-width: $large-screen) {
  .header { z-index: 200;
    &__main {
      .row { flex-wrap: nowrap;}
    }
    &__logo { max-width: 100px;}
    &__menu-wrapper { justify-content:flex-end;}
    &__mobile-menu-toggle{ display: block;}
    &__mobile-menu-toggle {font-size:$fz--text--big; text-decoration: none; color:white; font-weight: 700;
                           display: inline-block; padding: 5px 10px; background: $primary;;
    }
    &__menu-container { position: fixed; right: 0; top: 0; height: 100%;background: white;padding: 80px 30px 30px;
      transform: translateX(100%);
      transition: transform .3s linear;
      &.active {transform: translateX(0); height: 100vh; max-width: 500px; }
    }
    &__menu {flex-direction:column; justify-content:flex-start; align-items: flex-end;}
    &__menu-item {margin-bottom: 40px;}
    &__menu-item-link { font-size: $fz--text--big; width: 100%; }
    &__mobile-menu-bottom-links {display: block;}
    .mobile-menu-bottom-links {
      &__item { display: none;
                width: 40px; height: 40px; border-radius: 50%;
        &--close {display: inline-block; position: absolute; top: 10px; right: 10px;
          background: $primary url('../../img/front/close.svg') center no-repeat; background-size: 40%;
        }
      }
    }
  }
}



@media screen and (max-width: $x-small-screen) {
  .header {
    &__top {display: none;}
    &__menu-container { max-width:none; width: 100vw; padding-bottom: 50px;}
    &__menu-item {margin-bottom: 20px;}
    &__mobile-menu-top-links { display:flex; flex-direction:column; align-items: flex-end; margin-bottom: 20px;
      .header__connect { color:$primary; margin-right: 0; margin-bottom: 20px;}
    }
    &__mobile-menu-bottom-links { display:flex; align-items: center; justify-content:space-around;
                                  position: absolute; bottom: 0; left: 0; width: 100%;padding-bottom: 20px;
      .mobile-menu-bottom-links {
        &__item { display: inline-block;
                  width: 68px; height: 68px; border-radius: 50%;
          &--close { position: static;
            background: $primary url('../../img/front/close.svg') center no-repeat; background-size: 40%;
          }
          &--download { position: static;
            background: $secondary url('../../img/front/download.svg') center no-repeat; background-size: 40%;
          }
          &--contact { position: static;
            background: $primary-lighter url('../../img/front/phone.svg') center no-repeat; background-size: 40%;
          }
          &--search { position: static;
            background: $primary url('../../img/front/search-white.svg') center no-repeat; background-size: 40%;
          }
        }
      }
    }
  }

  .langswitcher {
    &__active { color:$primary;
      &:after {content:'';
               display: inline-block; width: 10px; height: 5px; margin-left: 5px;
               background: transparent url('../../img/front/arrow-down-primary.svg') center no-repeat; background-size: contain;
      }
    }
  }
}
