//couleurs du projet
$primary: #106245;
$primary-lighter: #6BB775;
$primary-lighter-alt: #A5D4AC;
$primary-lightest: #E3F1E5;
$primary-darker: darken($primary, 10%);;
$secondary: #C59E2C;
$secondary-lighter: lighten($secondary, 10%);
$secondary-lightest: #FAF5E8;
$secondary-darker: #C59E2C;
$tertiary: #2E3644;
$tertiary: lighten($tertiary, 10%);
$tertiary: darken($tertiary, 10%);
$neutral : #575756;
$neutral-light : #DBDBDB;
$neutral-lighter : #E0E0E0;
$neutral-darker : #1B1C1D;
$neutral-light2: #EDEDED80;

$danger:#CC0033;
$warning:#E07602;
$success:#009934;

$border-color: $neutral-lighter;

$text-color: $tertiary;
$text-color-light: transparentize(black, .7);

$shadow: 1px 3px 0 transparentize(black, .8);
$shadow-text: 1px 1px 0 transparentize(black, .8);


// Font sizes
$fz--big-title: 1.875rem ;
$fz--h1: 3.125rem;
$fz--h2: 2.5rem;
$fz--h3: 1.75rem;
$fz--h4: 1.5rem;
$fz--h5: 1.125rem;
$fz--text: 1rem;
$fz--text--small: .875rem;
$fz--text--smaller: .625rem;
$fz--text--big: 1.125rem;
$fz--text--bigger: 1.25rem;
$fz--button: 1rem;

$fz--big-title-mobile: 1.25rem ;
$fz--h1-mobile: 1.25rem;
$fz--h2-mobile: 1rem;
$fz--h3-mobile: 1rem;
$fz--h4-mobile: 1rem;

// font


// Border radius sizes
$br-size : 20px;
$br-size--med : 30px;
$br-size--big : 50px;
$br-size--small : 15px;

// Screen sizes
$xlarge-screen: 1600px;
$large-screen: 1200px;
$medium-screen: 1025px ;
$small-screen: 769px;
$x-small-screen: 500px;
