
form {
  & > div { margin-bottom: 20px;}
  label {font-size: $fz--text--big; font-weight: 900; display: block;}
  input.text,
  input.title,
  input[type=email],
  input[type=password],
  input[type=tel],
  input[type=text],
  select,
  textarea { background: $primary-lightest 0% 0% no-repeat padding-box; padding: 12px; border: 2px solid $neutral-light2; border-radius: 10px; margin-top: 8px; margin-bottom: 0; width: 100%;
    &:focus { outline:none;}
    &::placeholder {font-family: 'Roboto', sans-serif; color:$neutral;}
    &.has-error{  border-bottom: 1px solid red; }
    &::-webkit-resizer{ background: none; }
  }
  textarea { height: auto; min-height: 180px;}
  label{ font-weight: normal; font-size: 1rem; }
  select { background: $primary-lightest url('../../img/front/arrow-down-primary-slim.svg') right 15px center no-repeat padding-box; background-size: 3%; padding: 12px; border: 2px solid $neutral-light2; border-radius: 10px; margin-top: 8px; margin-bottom: 0; appearance:none; width: 100%;

   }
  p {}
  &.form{
    input[type=text], select, input[type=checkbox], textarea{ margin-bottom: 20px; }
    .form__row{
      &--checkbox{ display: flex; align-items: center; justify-content: flex-start; margin-bottom: 20px;
        input{ margin: 0 10px 0 0 ; }
      }
    }
  }
  .alert-case label { color: $danger;}
}

.chosen-container.chosen-container-single { width: 100% !important;
  .chosen-single { background: transparent; background-image: none !important; box-shadow:none !important; border-radius:0; border:none !important; border-bottom: 1px solid $neutral-light !important;
    &.chosen-default {
      span { font-weight: 400; color:$neutral-lighter; text-transform: initial; font-size: $fz--text--small;}
    }
    &.chosen-single-with-deselect {
      span { text-transform: uppercase; font-size: $fz--text; color:black;}
      &.chosen-default {
        span { font-weight: 400; color:$neutral-lighter; text-transform: initial; font-size: $fz--text--small;}
      }
    }
    b {background-color: transparent!important; background-image:url('../../img/shop/short-arrow-down-neutral.svg')!important;  background-position:center right 0!important; background-repeat: no-repeat !important; background-size: 14px 8px !important;}
  }
  .chosen-drop { border:none !important; border-radius:0 !important;}
}

input[type=submit],
button {
  &:hover { }
}

.js-quantity-input {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]
{
  -moz-appearance: textfield;
}

.label_handling_units_number
{
  display: inline-block;
  padding: 10px !important
}

.js-quantity-input input {
  width: auto;
  max-width: 100px;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  margin: 0;
  padding: 0 20px;
  border: 1px solid $neutral-light;
}

.js-quantity-input input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 42px;
}

.js-quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.js-quantity-button.js-quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.js-quantity-button.js-quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}

.weekpicker{ display: none !important; width: auto; height: 300px; overflow: auto; padding: 10px;
  ul{ list-style: none; margin-bottom: 0 !important;
    li{
      &:before{ display: none; }
      a{ color: $neutral; padding: 5px 0; font-size: 12px; display: inline-block; width: 100%; text-decoration: none;
        &:hover{ color: $primary; }
      }
    }
  }
  &.active{ display: inline-block !important; border: 1px solid $neutral-lighter; }
  &::-webkit-scrollbar {
    width: 2px;
  }
}
