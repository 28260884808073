.contact{ display: flex; justify-content: center; margin-top: 50px;
  &__container{ display: flex; justify-content: space-between; width: 83%; }
  aside, form{ width: 50%; }
  aside{ padding-right: 10%;
    h2{ color: $primary; font-size: 1.75rem; font-weight: 300; }
  }
  .form{ display: flex; flex-wrap: wrap; margin-bottom: 100px;
    &__line{ width: 100%;
      &--mid{ width: 50%; }
    }
    &__radio-container{ display: flex; align-items: center; margin-top: 10px;
      input[type=radio]{ width: 18px; height: 18px; margin-right: 10px;  display: inline-block; }
      > label{ margin-right: 20px; display: inline-block; }
    }

    &__checkbox-container{ display: flex; align-items: flex-start; margin-top: 10px;
      > div{ display: flex; align-items: flex-start; margin-top: 10px; flex-direction: row-reverse;  }
      input[type=checkbox]{ width: 14px; height: 14px; margin-right: 10px;  display: inline-block; }
    }
    input[type=submit]{ border-radius: 30px; }
  }
}
#form_join .form__checkbox-container{ display: flex; align-items: flex-start; margin-top: 10px;
  > div{ display: flex; align-items: flex-start; margin-top: 10px; flex-direction: row-reverse;  }
  input[type=checkbox]{ width: 14px; height: 14px; margin-right: 10px;  display: inline-block; }
}
