.alert {width: auto; padding: 20px 60px 20px 20px; color:white; margin-bottom: 10px; position: relative;
        display: none;
  strong { font-weight: 700; text-transform: capitalize;}
  &__container {position:fixed; bottom: 20px; right: 30px; z-index: 1000;
    display:flex; flex-direction:column; justify-content: flex-end; align-items:flex-end;
  }
  &--success { background-color: $success;}
  &--warning { background-color: $warning;}
  &--danger { background-color: $danger;}
  &--notice { background-color: $secondary-lighter;}
  .close { position: absolute; top: 50%; right: 20px; transform: translateY(-50%);
           appearance:none; border:none; background-color: transparent;
           color:white; font-size: $fz--h3;
         }
  &.show { display: block;}
}
