body { line-height: 1.3; font-family: 'Serenity', sans-serif; color:$text-color; -webkit-font-smoothing: antialiased; font-size: $fz--text--big;}


h1,
.h1 { font-size: $fz--h1; font-family: 'Serenity', sans-serif; font-weight: 700;  margin-bottom: 32px; line-height: 52px; color:$primary;}
h2,
.h2 { font-size: $fz--h2; font-family: 'Serenity', sans-serif; font-weight: 700; font-weight: 400; color:black; line-height: 1.125; margin-bottom: 32px;}
h3,
.h3 { font-size: $fz--h3; font-family: 'Serenity', sans-serif; font-weight: 300;  margin-bottom: 10px; color:$primary-lighter;}
h4,
.h4 { font-size: $fz--h4; font-family: 'Serenity', sans-serif; font-weight: 500;  margin-bottom: 6px;}
h5,
.h5{ font-size: $fz--h5; font-family: 'Serenity', sans-serif; font-weight: 500;  margin-bottom: 6px;}

.inner-content {

  p { margin-bottom: 20px; font-family: 'Serenity', sans-serif; font-weight: 500;
    em { font-style: italic;}
    b, strong { font-weight: 700;}
    a {color:$primary;}
  }


  ul:not(.chosen-results) { margin-bottom: 20px;
    li { position: relative; padding-left: 0; margin-bottom: 5px;
      &:before { content:'• '; position: static; width: auto; height: auto; border-radius:0; background-color: transparent; color:$secondary;}
      em { font-style: italic;}
      b, strong { font-weight: 700;}
      a {color:$primary;}
    }
  }
  ol { margin-bottom: 20px;
    counter-reset: my-awesome-counter;
    li {counter-increment: my-awesome-counter;
      &:before {
        content: counter(my-awesome-counter) ". ";
        color: $primary; font-weight: bold; font-size: $fz--text--small;
      }
      em { font-style: italic;}
      b, strong { font-weight: 700;}
      a {color:$primary;}
    }
  }

  figcaption {font-family: 'Serenity', sans-serif; font-style: italic; font-size: $fz--text; margin-top: 5px; }
}

.link { display: inline-block; position: relative;
        color:$primary; text-decoration: none;
        margin-left: 20px; font-size: $fz--text;
  &:before {content:'';
            position: absolute; top: 5px; right: calc(100% + 15px);
            width: 5px; height: 8px;
            background: transparent url('../../img/shop/short-arrow-right-primary.svg') center no-repeat; background-size: contain;
            transition: right .3s ease;
  }
  &:after {content:'';
           position: absolute; top: 100%; left: 0;
           display: inline-block;
           width: 100%; height: 1px;
           background-color: transparentize($primary, .5);
  }
  &--white { color:white;
    &:before { background: transparent url('../../img/shop/short-arrow-right-white.svg') center no-repeat; background-size: contain;}
    &:after { background-color: transparentize(white, .5);}
  }
  &--secondary {color:$secondary;
    &:before { background: transparent url('../../img/shop/short-arrow-right-secondary.svg') center no-repeat; background-size: contain;}
    &:after { background-color: transparentize($secondary, .5);}
  }

  &:hover {
    &:before {right: calc(100% + 5px);}
  }
}

.text-center{ text-align: center; }
.text-primary{ color: $primary; }

@media screen and (max-width: $large-screen) {
  h1,
  .h1 { font-size: calc(#{$fz--h1} / 1.5) ; font-family: 'Serenity', sans-serif; font-weight: 700;  margin-bottom: 24px; line-height: 1.3; color:$primary;}
  h2,
  .h2 { font-size: calc(#{$fz--h2} / 1.5); font-family: 'Serenity', sans-serif; font-weight: 700; font-weight: 400; color:black; line-height: 1.125; margin-bottom: 24px;}
  h3,
  .h3 { font-size: calc(#{$fz--h3} / 1.2); font-family: 'Serenity', sans-serif; font-weight: 300;  margin-bottom: 10px; color:$primary-lighter;}
  h4,
  .h4 { font-size: calc(#{$fz--h4} / 1.2); font-family: 'Serenity', sans-serif; font-weight: 500;  margin-bottom: 6px;}
  h5,
  .h5{ font-size: calc(#{$fz--h5} / 1.2); font-family: 'Serenity', sans-serif; font-weight: 500;  margin-bottom: 6px;}
}
