.accordion { position: relative; padding-left: 50px;
  &__item { border-top:1px solid $neutral-light;
    &:first-of-type { border-top: none; }
  }
  &__item-head { position: relative;
                 padding: 40px 0 40px 100px;
    &:hover {cursor: pointer;}
    &:focus { outline: none;}
    &:after { content: ''; width: 40px; height: 40px;
              position: absolute; top: 50%; left: 20px; transform: translateY(-50%);
              background:transparent url('../../img/shop/accordion-plus-primary.svg') center no-repeat; background-size: 46%; border-radius:50%;
              transition: transform .3s linear;}
    &.open {
      &:after { background:transparent url('../../img/shop/accordion-minus-primary.svg') center no-repeat; background-size: 46%;}
    }
  }
  &__item-heading { font-weight: 900; font-size: 20px; line-height: 1.1;}
  &__item-collapsing { padding-bottom: 40px; padding-left: 15%;
    p { max-width: 675px; padding-left: 15px; border-left:1px solid $neutral-light; color:$neutral-darker;}
  }
}

@media screen and (max-width: $medium-screen) {
  .accordion { padding-left: 0;}
}
