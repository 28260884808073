div[aria-hidden='true'] {
  display: none;
}

.tabs {
  &__title-list { display:flex; padding-top: 5px;
                  position: relative; align-items: flex-start;
                  border-bottom:2px solid transparentize($primary, .6);
  }
  &__item-tab { display: inline-block; padding-left: 0 !important;
                flex-grow: 1; text-align: center; margin-bottom: 0 !important;
                position: relative;
    &:hover { cursor: pointer;}
    &:focus { outline: none;}
    &[data-index='1'] { z-index: 8;}
    &[data-index='2'] { z-index: 7;}
    &[data-index='3'] { z-index: 6;}
    &[data-index='4'] { z-index: 5;}
    &[data-index='5'] { z-index: 4;}
    &[data-index='6'] { z-index: 3;}
    &[data-index='7'] { z-index: 2;}
    &[data-index='8'] { z-index: 1;}
    &[aria-selected='true'],
    &.active,
    &:hover { z-index: 10;
      &:after { content:'';
                position: absolute; top: 100%; left: 0;
                display: inline-block; width: 100%; height: 2px;
                background-color: $primary;
      }
      span,
      a { font-weight: 700; color:$primary;}
    }
    span,
    a { display: inline-block; padding: 15px 0; color:transparentize($primary, .6); text-decoration: none;}
    &:before {content:none !important;}

  }
  &__item-content { padding: 30px 0; }
}
