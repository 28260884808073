.footer { background: $primary url('../../img/front/footer-bg.svg') right -10px bottom -20px no-repeat; padding: 70px 0 40px; color:white; margin-top: 100px;
  &__logo-container {display: inline-block; margin-bottom: 20px; }
  &__logo { max-width: 166px; height: auto;}
  &__text {margin-bottom: 10px;}
  &__social-networks {}
  &__social-link {display: inline-block; width: 36px; height: 36px; border-radius:50%; margin-right: 5px;
    &--lnkdn {background: $primary-lighter url('../../img/front/lnkdn.svg') center no-repeat; background-size: 40%;}
    &--twtr {background: $primary-lighter url('../../img/front/twtr.svg') center no-repeat; background-size: 40%;}
  }
  &__menu {}
  &__menu-item {}
  &__menu-item-link {display: inline-block; color:white; text-decoration: none; font-size: $fz--text--big; line-height: 20px; margin-bottom: 8px;
    &:hover,
    &--active {text-decoration: underline;font-weight: 700;}

  }
  &__contacts {}
  &__contacts-title {color:white; font-size: $fz--text--big; line-height: 20px; margin-bottom: 8px; font-weight: 700;}
  &__contacts-address {
    &:last-of-type {margin-bottom: 15px;}
  }
  &__press-link {display: block; margin-top: 20px; color:white; text-decoration: underline; font-size: $fz--text--big; line-height: 20px;
    &:hover { text-decoration: none;}
  }
  &__sublinks{  display: flex; justify-content: center; align-items: center; margin-top: 50px;
    a{ font-size: 1rem; color: #fff; text-decoration: none;
      &.kromi{ color: $secondary; margin: 0 40px; }
    }
  }
}

@media screen and (max-width: $medium-screen) {

}
