// EXEMPLE
//
// @font-face {
//   font-family: 'NOM_DE_LA_TYPO';
//   font-style: normal;
//   src: url('fonts/NOM_DE_LA_TYPO.eot');
//   src: local('NOM_DE_LA_TYPO'), url('fonts/NOM_DE_LA_TYPO.eot') format('embedded-opentype'), url('fonts/NOM_DE_LA_TYPO.woff') format('woff');
// }
//
// .nom-de-la-typo{font-family: 'NOM_DE_LA_TYPO' !important;}

@font-face {
  font-family: 'lovelo';
  font-style: normal;
  font-display: swap;
  src: local('lovelo'), url('fonts/lovelo.woff') format('woff');
}
